<script>
import { Pie } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
export default {
  extends: Pie,

  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.addPlugin(ChartJsPluginDataLabels);
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
