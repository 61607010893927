import mensagens from '@/utils/mensagens'

export default {
  install: (Vue) => {
    Vue.prototype.$mensagens = {
      _: function (chave) {
        return mensagens[chave]
      },
    }
  },
}
