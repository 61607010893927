export default [
  {
    name: 'Listar Feriados',
    path: '/feriados',
    component: () => import('@/views/pages/feriados/FeriadosListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Feriado',
    path: '/feriados/adicionar',
    component: () => import('@/views/pages/feriados/FeriadosAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Feriado',
    path: '/feriados/:id/editar',
    component: () => import('@/views/pages/feriados/FeriadosEditar'),
    meta: { somenteAutenticado: true },
  },
  {
  name: 'Mostrar Feriado',
  path: '/feriados/:id/mostrar',
  component: () => import('@/views/pages/feriados/FeriadosMostrar'),
  meta: { somenteAutenticado: true },
  },
]
