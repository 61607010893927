import axios from "@/plugins/axios";

export default {
  async listar(dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`empresas?${parametros}`);
  },

  async buscar(id) {
    return await axios.get(`empresas/${id}`);
  },

  async inserir(dados) {
    return await axios.post("empresas", dados);
  },

  async atualizar(id, dados) {
    return await axios.put(`empresas/${id}`, dados);
  },

  async deletar(id) {
    return await axios.delete(`empresas/${id}`);
  },
  async listarUsuariosEmpresa(id, dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`empresas/${id}/usuarios?${parametros}`);
  },
  async listarAreasEmpresa(id, dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`empresas/${id}/areas?${parametros}`);
  },

  async upgradePlano(idEmpresa, dados) {
    return await axios.put(`empresas/${idEmpresa}/plano`, dados);
  },

  async mensalidadesEmpresa(id) {
    return await axios.get(`empresas/${id}/mensalidades`);
  },
  async statusFinanceiro(id) {
    return await axios.get(`empresas/${id}/status-financeiro`);
  },
};
