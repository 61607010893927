export default [
  {
    name: "Administração - Listar Configurações das Áreas",
    path: "/area_configuracaoAdmin",
    component: () =>
      import("@/views/pages/areaConfiguracaoAdmin/AreaConfiguracaoListar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Administração - Adicionar Configuração na Área",
    path: "/empresa/:empresa/area_configuracaoAdmin/adicionar",
    component: () =>
      import("@/views/pages/areaConfiguracaoAdmin/AreaConfiguracaoAdicionar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Administração - Editar Configuração da Área",
    path: "/empresa/:empresa/area_configuracaoAdmin/:id/editar",
    component: () =>
      import("@/views/pages/areaConfiguracaoAdmin/AreaConfiguracaoEditar"),
    meta: { somenteAutenticado: true },
  },
];
