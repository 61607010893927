export default [
    {
        name: 'Listar Convênios',
        path: '/areas/:id/convenio',
        component: () => import('@/views/pages/convenios/ConvenioListar'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Adicionar Convênio',
        path: '/areas/:id/convenio/adicionar',
        component: () => import('@/views/pages/convenios/ConvenioAdicionar'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Editar Convênio',
        path: '/areas/:idArea/convenio/:id/editar',
        component: () => import('@/views/pages/convenios/ConvenioEditar'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Mostrar Convênio',
        path: '/areas/:idArea/convenio/:id/mostrar',
        component: () => import('@/views/pages/convenios/ConvenioMostrar'),
        meta: { somenteAutenticado: true },
      },
]

