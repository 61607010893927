import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

//Português
import pt from "vuetify/lib/locale/pt";
// Espanhol
import es from "vuetify/lib/locale/es";
//Inglês
import en from "vuetify/lib/locale/en"; 

export default new Vuetify({
    lang: {
        locales: { pt, es, en }, 
        current: "pt",
    },
});
