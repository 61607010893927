import axios from '@/plugins/axios'

export default {

  async listar () {
    return await axios.get('tipos-pagamentos')
  },
  async inserir (id, dados) {
    return await axios.put(`areas/${id}/tipos-pagamentos`, dados)
  },

}
