import axios from "@/plugins/axios";

export default {
  async gerarPIX(dados) {
    return await axios.post("gateway-payments/liquidPix", dados);
  },

  async ReconsultarPix(id) {
    return await axios.get(`gateway-payments/liquidReconsultarPix/${id}`);
  },
};
