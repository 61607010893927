<template>
  <v-container>
    <v-card class="d-flex flex-column text-center">
      <h1>{{ dados.nome }}</h1>
      <v-row class="py-10">
        <v-col lg="4" cols="12" class="grafico">
          <h3 class="pb-5">Áreas ocupadas e livres</h3>
          <grafico-pie
            class="pie"
            v-if="loaded"
            :chartdata="chartDataOcupadasLivres"
            :options="options1"
          />
        </v-col>
        <v-col lg="4" cols="12" class="grafico">
          <h3 class="pb-5">Tipos de pagamentos</h3>
          <grafico-pie
            class="pie"
            v-if="loaded"
            :chartdata="chartDataTiposPagamento"
            :options="options2"
          
          />
        </v-col>
        <v-col lg="4" cols="12" class="grafico">
          <h3 class="pb-5">Tabelas da área</h3>
          <grafico-pie
            class="pie"
            v-if="loaded"
            :chartdata="chartDataTabelasArea"
            :options="options3"
           
          />
        </v-col>
      </v-row>
      <v-row class="py-5">
        <v-col cols="12">
          <apex-line-charts :chartdata="dados" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import numeral from "numeral";
export default {
  props: {
    dados: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    loaded: false,
    chartDataTiposPagamento: null,
    chartDataOcupadasLivres: null,
    options1: null,
    options2: null,
    options3: null,
  }),
  async mounted() {
    this.loaded = false;
    try {
      /* ----------------------------------- */
      (this.chartDataOcupadasLivres = {
        labels: ["Ocupadas", "Livres"],
        datasets: [
          {
            label: this.dados.nome,
            backgroundColor: [
              "#ADEFD1FF",
              "#00203FFF",
              "#F0C31D ",
              "#119E6B",
              "#EEE8AA",
            ],
            data: [this.dados.vagas.ocupadas, this.dados.vagas.livres],
          },
        ],
      }),
        (this.options1 = {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateScale: true,
            duration: 2000,
          },
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 15,
            },
          },
          plugins: {
            datalabels: {
              color: "#FBDE44FF",
              align: "end",
              borderWidth: 3,
              borderColor: "#F65058FF",
              //textStrokeColor: "#B3C7D6FF",
              //textStrokeWidth: 3,
              backgroundColor: "#28334AFF",
              borderRadius: 50,
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        });
      /* ----------------------------------- */

      const labelsTipoPagamentos = [];
      const valoresTipoPagamentos = [];

      this.dados.tipos_pagamentos.forEach((element) => {
        labelsTipoPagamentos.push(element.descricao);
        valoresTipoPagamentos.push(element.valor);
      });

      (this.chartDataTiposPagamento = {
        labels: labelsTipoPagamentos,
        datasets: [
          {
            label: this.dados.nome,
            backgroundColor: [
              "#2460A7FF",
              "#85B3D1FF",
              "#B3C7D6FF",
              "#D9B48FFF",
            ],
            data: valoresTipoPagamentos,
          },
        ],
      }),
        (this.options2 = {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return `${data.labels[tooltipItem.datasetIndex]} - ${numeral(
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ]
                ).format("0,0")}`;
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateScale: true,
            duration: 2000,
          },
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 15,
            },
          },
          plugins: {
            datalabels: {
              formatter: function (value, context) {
                var index = context.dataIndex;
                value = context.dataset.data[index];
                return value.toLocaleString().replaceAll(".", ",");
              },
              color: "#30110D",
              align: "end",
              borderWidth: 3,
              borderColor: "#30110d",
              //textStrokeColor: "#B3C7D6FF",
              //textStrokeWidth: 3,
              backgroundColor: "#f2bc94",
              borderRadius: 50,
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        });
      /* ----------------------------------- */

      const labelsTabelasAreas = [];
      const valoresTabelasAreas = [];

      this.dados.tabelas_area.forEach((element) => {
        labelsTabelasAreas.push(element.descricao);
        valoresTabelasAreas.push(element.quantidade);
      });

      (this.chartDataTabelasArea = {
        labels: labelsTabelasAreas,
        datasets: [
          {
            label: this.dados.nome,
            backgroundColor: ["#FEE715FF", "#101820FF"],
            data: valoresTabelasAreas,
          },
        ],
      }),
        (this.options3 = {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateScale: true,
            duration: 2000,
          },

          // tooltips: {
          //   callbacks: {
          //     label: function(tooltipItem, data) {
          //       return (
          //         data["labels"][tooltipItem["index"]] +
          //         ": " +
          //         data["datasets"][0]["data"][tooltipItem["index"]] +
          //         "%"
          //       );
          //     },
          //   },
          // },

          legend: {
            position: "bottom",
            labels: {
              boxWidth: 15,
            },
          },
          plugins: {
            datalabels: {
              color: "#efc9af",
              align: "end",
              borderWidth: 3,
              borderColor: "#1f8ac0",
              //textStrokeColor: "#B3C7D6FF",
              //textStrokeWidth: 3,
              backgroundColor: "#104c91",
              borderRadius: 50,
              font: {
                weight: "bold",
                size: 15,
              },
              // formatter: (value, ctx) => {
              //   let datasets = ctx.chart.data.datasets;
              //   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
              //     let sum = datasets[0].data.reduce((a, b) => a + b, 0);
              //     let percentage = Math.round((value / sum) * 100) + "%";
              //     return percentage;
              //   }
              // },
            },
          },
        });
      /* ----------------------------------- */

      this.loaded = true;
    } catch (e) {
      this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
    }
  },
};
</script>
<style>
.grafico {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* .pie {
  width: 90%;
} */

@media (min-width: 1064px) and (max-width: 1399.98px) {
  #pie-chart {
    width: 200px !important;
    height: 200px !important;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  #pie-chart {
    width: 100% !important;
    height: 100% !important;
    padding: 1em;
  }
}
@media (max-width: 500px) {
  #pie-chart {
    width: 100% !important;
    height: 100% !important;
    padding: 1em;

  }
}
</style>
