<template>
  <v-dialog
    :width="tamanho"
    v-model="$store.state.modal.modal"
    @click:outside="$store.commit(`modal/SET_MODAL`, false)"
  >
    <ticket-montar v-if="tipo == 1" />

    <pagar-pix v-else-if="tipo == 2" />

    <trocar-plano v-else-if="tipo == 3" />

    <v-row v-else>
      <h1>Não foi possivel continuar</h1>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    tipo() {
      return this.$store.state.modal.type;
    },
    tamanho() {
      let num = this.$store.state.modal.type;
      if (num == 1) {
        return "300px";
      } else {
        return "70%";
      }
    },
  },
};
</script>

<style scoped>
.bg-corDoPix {
  background-color: #00bdae;
  color: white;
  text-transform: uppercase;
  justify-content: center;
}
</style>