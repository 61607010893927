<template>
    <v-autocomplete
      :value="value"
      dense
      outlined
      label="Planos"
      :items="planosList"
      item-text="descricao"
      item-value="id"
      :readonly="readonly"
      :loading="carregando"
      :disabled="carregando"
      :error-messages="errorMessages"
      autocomplete="false"
      hide-details="auto"
      @input="(e) => $emit('input', e)"
    />
  </template>
  
  <script>
  import planos from "@/api/planos";
  
  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      errorMessages: {
        type: Array,
        default: () => {},
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
  
    data() {
      return {
        planosList: [],
        carregando: false,
      };
    },
  
    mounted() {
      this.buscarPlanos();
    },
  
    methods: {
      async buscarPlanos() {
        try {
          this.carregando = true;
  
          const resposta = await planos.listar();
  
          this.planosList = resposta.data.data;
        } catch (e) {
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        } finally {
          this.carregando = false;
        }
      },
    },
  };
  </script>
  