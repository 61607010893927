export default [
    {
      name: 'Relatório de Arrecadação Diaria',
      path: '/arrecadacao-diaria',
      component: () => import('@/views/pages/relatorios/RelatorioArrecadacaoDiaria'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Relatório de Arrecadação Caixa Operador',
      path: '/arrecadacao-caixa-operador',
      component: () => import('@/views/pages/relatorios/RelatorioArrecadacaoCaixaOperador'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Relatório de Consulta Movimentação de Veículos',
      path: '/consulta-movimentacao',
      component: () => import('@/views/pages/relatorios/RelatorioConsultarMovimentacaoVeiculo'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Relatório de Fechamento de Caixa',
      path: '/fechamento-caixa',
      component: () => import('@/views/pages/relatorios/RelatorioFechamentoCaixa'),
      meta: { somenteAutenticado: true },
    },
  ]
  