export default {
  namespaced: true,
  state: {
    //Modal Base
    modal: false,
    type: 0,

    //Modal Montar Ticket,
    ticketTexto: null,

    //Modal Trocar Plano,
  },
  mutations: {
    SET_MODAL(state, payload) {
      state.modal = payload;
    },
    SET_TYPE(state, payload) {
      state.type = payload;
    },
    SET_TICKETEXTO(state, payload) {
      state.ticketTexto = payload;
    },
  },
};
