<template>
  <v-app id="inspire">
    <carregando v-if="carregandoTela" :opacidade="1" />
    <v-img src="@/assets/background.jpeg" class="background-login" />
    <core-snackbar />

    <v-row align="center" justify="center" class="mx-0 my-0">
      <v-card class="login-card">
        <v-toolbar color="primary" flat dark>
          <v-toolbar-title> Login </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-4">
          <v-form @submit="login">
            <v-row>
              <v-col>
                <validation-observer ref="observer">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Usuário"
                    vid="login"
                  >
                    <v-text-field
                      v-model="form.login"
                      outlined
                      hide-details="auto"
                      label="Usuário"
                      class="mb-4"
                      autocomplete="nope"
                      :error-messages="errors"
                      prepend-icon="mdi-account-circle"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Senha"
                    vid="senha"
                  >
                    <v-text-field
                      v-model="form.senha"
                      outlined
                      hide-details="auto"
                      type="Password"
                      label="Senha"
                      :error-messages="errors"
                      prepend-icon="mdi-lock"
                    />
                  </validation-provider>
                </validation-observer>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col class="text-left">
                <v-btn
                  :class="{
                    'white-text': $vuetify.theme.dark,
                  }"
                  small
                  outlined
                  to="/esquecisenha"
                  style="border: none"
                >
                  Esqueceu a senha?
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  :class="{
                    'white-text': $vuetify.theme.dark,
                  }"
                  depressed
                  outlined
                  :loading="carregando"
                  type="submit"
                >
                  <v-icon class="mr-2"> mdi-login </v-icon>
                  Entrar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
import autenticacao from "@/api/autenticacao";
import permissoes from "@/api/permissoes";

export default {
  data() {
    return {
      carregandoTela: false,
      carregando: false,
      form: {
        login: null,
        senha: null,
      },
    };
  },
  mounted() {
    this.loading();
  },
  methods: {
    ...mapMutations({
      setUsuario: "usuario/SET_USUARIO",
      setPermissao: "usuario/SET_PERMISSOES",
    }),

    loading() {
      this.carregandoTela = true;
      setTimeout(() => (this.carregandoTela = false), 1000);
    },

    async login(evento) {
      evento.preventDefault();

      try {
        this.carregando = true;

        const resposta = await autenticacao.login(this.form);
        const usuario = resposta.data.usuario;
        usuario.access_token = resposta.data.access_token;
        this.setUsuario(usuario);

        const respostaPermissoes = await permissoes.listarPermissoesUsuarios();
        this.setPermissao(respostaPermissoes.data.data);

        this.$router.push("/");
      } catch (e) {
        console.log(e);
        if (e.response && e.response.status === 422) {
          return this.$refs.observer.setErrors(e.response.data.erros);
        }
        if (e.response && e.response.status === 401) {
          return this.$snackbar.mostrar({
            cor: "warning",
            mensagem: "Login ou senha inválida",
          });
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>

<style>
.background-login {
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-card {
  width: 500px;
}

@media (max-width: 576px) {
  .login-card {
    width: 90%;
  }
}
</style>
