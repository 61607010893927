export default [
  {
    name: "Listar Clientes",
    path: "/clientes",
    component: () => import("@/views/pages/clientes/ClienteListar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Adicionar Cliente",
    path: "/cliente/adicionar",
    component: () => import("@/views/pages/clientes/ClienteAdicionar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Editar Cliente",
    path: "/cliente/:cliente/editar",
    component: () => import("@/views/pages/clientes/ClienteEditar"),
    meta: { somenteAutenticado: true },
  },
  //CONTRATO DO CLIENTE
  {
    name: "Contratos do Cliente",
    path: "/cliente/:cliente/contratos",
    component: () => import("@/views/pages/contratos/ContratoListar"),
    meta: { somenteAutenticado: true },
  },

  {
    name: "Novo contrato do cliente",
    path: "/cliente/:cliente/contrato/adicionar",
    component: () => import("@/views/pages/contratos/ContratoAdicionar"),
    meta: { somenteAutenticado: true },
  },

  {
    name: "Editar contrato do cliente",
    path: "/cliente/:cliente/contrato/:contrato/editar",
    component: () => import("@/views/pages/contratos/ContratoEditar"),
    meta: { somenteAutenticado: true },
  },
  //VEICULOS DO CONTRATO
  {
    name: "Veiculos do contrato",
    path: "/cliente/:cliente/contrato/:contrato/veiculos",
    component: () => import("@/views/pages/veiculos/VeiculoListar"),
    meta: { somenteAutenticado: true },
  },

  {
    name: "Adicionar veiculo ao contrato",
    path: "/cliente/:cliente/contrato/:contrato/veiculo/adicionar",
    component: () => import("@/views/pages/veiculos/VeiculoAdicionar"),
    meta: { somenteAutenticado: true },
  },

  {
    name: "Editar veiculo do contrato",
    path: "/cliente/:cliente/contrato/:contrato/veiculo/:veiculo/editar",
    component: () => import("@/views/pages/veiculos/VeiculoEditar"),
    meta: { somenteAutenticado: true },
  },
];
