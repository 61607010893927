<template>
  <div :key="reload"
    :reloadd="reloadd"
  >
    <graficos-areas
      class="py-5 px-5"
      v-for="(item, index) in graficos"
      :key="index"
      :dados="graficos[index]"
    />
  </div>
</template>

<script>
import graficoApi from "@/api/dashboard";
export default {
  data() {
    return {
     
      graficos: {},
      timer: "",
      reloadd: 0,
      reload: 0,
    };
  },

  created() {
    this.timer = setInterval(this.criarGraficoPie, 30000);
  },
  mounted() {
    this.criarGraficoPie();
  },
  methods: {
    forceRerender() {
      this.reload += 1;
    },
    async criarGraficoPie() {
      this.$store.commit(`layout/SET_LOADING`, true);
      try {
        var resposta = await graficoApi.totalAreas();
        this.graficos = resposta.data.data;
        this.forceRerender();
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
<style scoped>
.v-overlay {
  align-items: flex-start;
  padding-top: 20%;
  position: absolute;
}
</style>
