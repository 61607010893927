<template>
  <div>
    <v-btn
      v-if="temPermissao"
      depressed
      color="primary"
      class="white--text"
      :to="to"
    >
      <v-icon class="mr-2">
        mdi-plus
      </v-icon>
      Adicionar
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn
            depressed
            color="primary"
            class="white--text espaco-esquerda"
            :to="to"
            disabled
          >
            <v-icon class="mr-2">
              mdi-plus
            </v-icon>
            Adicionar
          </v-btn>
        </span>
      </template>
      <span>Sem permissão</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ButtonAdicionar",
  data() {
    return {};
  },
  props: {
    to: {
      type: String,
      default: "/",
    },
    temPermissao: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped></style>
