<template>
  <v-tab v-if="temPermissao" :to="to">
    Convênio
  </v-tab>

  <v-tab v-else :to="to" disabled>
    Convênio
  </v-tab>
</template>

<script>
export default {
  name: "tab-rotas-convenio",
  data() {
    return {};
  },
  props: {
    to: {
      type: String,
      default: "/",
    },
    temPermissao: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped></style>
