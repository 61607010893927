<template>
  <v-card :key="cont">
    <v-textarea
      background-color="amber lighten-4"
      color="orange orange-darken-4"
      :readonly="true"
      v-model="textoDialog"
      class="pa-0 ma-0"
      id="alinharCentro"
      auto-grow
    />

    <v-card-actions class="pt-2 justify-center transparent">
      <v-btn @click="$store.commit(`modal/SET_MODAL`, false)"> Fechar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      nomesTicket: [
        { id: "%empresa_bairro%", valor: "Bairro" },
        { id: "%empresa_cidade%", valor: "Cidade" },
        { id: "%empresa_cnpj%", valor: "00.000.000/0000-00" },
        { id: "%data_hora_entrada%", valor: "00:00" },
        { id: "%data_hora_saida%", valor: "00:00" },
        { id: "%empresa_logradouro%", valor: "Rua..." },
        { id: "%forma_pagamento%", valor: "Dinheiro" },
        { id: "%area%", valor: "Área" },
        { id: "%box%", valor: "Lugar" },
        { id: "%usuario%", valor: "Usuario" },
        { id: "%empresa_fantasia%", valor: "Empresa" },
        { id: "%ticket%", valor: "0000000" },
        { id: "%placa%", valor: "FFF-0000" },
        { id: "%empresa_razao%", valor: "Empresa" },
        { id: "%tipo_veiculo%", valor: "Carro" },
        { id: "%valido_ate%", valor: "00/00/0000" },
        { id: "%valor%", valor: "R$ 00.00" },
      ],
      cont: 0,
    };
  },

  computed: {
    textoDialog() {
      let texto = this.$store.state.modal.ticketTexto;
      this.nomesTicket.forEach((element) => {
        texto = texto.replaceAll(element.id, element.valor);
      });
      return texto;
    },
  },
  watch: {
    "$store.state.modal.modal"() {
      this.cont++;
    },
  },
};
</script>

<style>
.v-textarea textarea {
  line-height: 1.35rem !important;
}
.v-card__text {
  padding-bottom: 0;
}
.v-text-field__details {
  display: none !important;
}
#alinharCentro {
  text-align: center !important;
  line-height: 1.25rem !important;
  padding: 5px;
  color: black !important;
  /* height: 70vh !important;  */
}
</style>


