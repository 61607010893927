<template>
  <v-btn
    dark
    color="teal"
    @click="
      $store.commit(`modal/SET_MODAL`, true), $store.commit(`modal/SET_TYPE`, 1)
    "
  >
    <v-icon dark> mdi-file-check </v-icon>
    Visualizar
  </v-btn>
</template>
 
 <script>
export default {
  name: "ButtonVisualizar",
};
</script>
 