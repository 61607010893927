<template>
  <div>
    <v-btn
      depressed
      color="green"
      class="white--text"
      :loading="carregando"
      v-if="temPermissao"
      v-bind="$props"
      @click="$emit('click')"
    >
      <v-icon class="mr-2">
        mdi-magnify
      </v-icon>
      Buscar
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn
            depressed
            disabled
            color="green"
            class="white--text espaco-esquerda"
            :loading="carregando"
            v-bind="$props"
            @click="$emit('click')"
          >
            <v-icon class="mr-2">
              mdi-magnify
            </v-icon>
            Buscar
          </v-btn>
        </span>
      </template>
      <span>Sem permissão</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ButtonSalvar",

  props: {
    carregando: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    temPermissao: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
