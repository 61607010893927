import axios from '@/plugins/axios'

export default {

  async login (dados) {
    return await axios.post('auth/login', dados)
  },

  async me () {
    return await axios.get('auth/me')
  },

}
