<template>
  <v-snackbar
    v-model="snackbar.mostrar"
    right
    top
    app
    :color="snackbar.cor"
    :timeout="snackbar.timeout"
  >
    <v-icon
      v-if="snackbar.cor === 'error'"
      class="mr-2"
    >
      mdi-alert-circle
    </v-icon>

    <v-icon
      v-if="snackbar.cor === 'success'"
      class="mr-2"
    >
      mdi-check
    </v-icon>

    {{ snackbar.mensagem }}

    <template v-slot:action>
      <v-btn
        color="white"
        text
        small
        @click="fechar"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'CoreSnackbar',

    computed: {
      ...mapState('layout', ['snackbar']),
    },

    methods: {
      fechar () {
        this.$store.state.layout.snackbar.mostrar = false
      },
    },

  }
</script>
