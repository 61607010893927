export default [
  {
    name: "Listar Configurações das Áreas",
    path: "/area_configuracaoUser",
    component: () =>
      import("@/views/pages/areaConfiguracaoUser/AreaConfiguracaoListar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Editar Configuração da Área",
    path: "/empresa/:empresa/area_configuracaoUser/:id/editar",
    component: () =>
      import("@/views/pages/areaConfiguracaoUser/AreaConfiguracaoEditar"),
    meta: { somenteAutenticado: true },
  },
];
