
export default [
    {
      name: 'Listar Áreas',
      path: '/areas',
      component: () => import('@/views/pages/areas/AreasListar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Adicionar Área',
      path: '/areas/adicionar',
      component: () => import('@/views/pages/areas/AreasAdicionar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Editar Área',
      path: '/areas/:id/editar',
      component: () => import('@/views/pages/areas/AreasEditar'),
      meta: { somenteAutenticado: true },
    },
    {
    name: 'Mostrar Área',
    path: '/areas/:id/mostrar',
    component: () => import('@/views/pages/areas/AreasMostrar'),
    meta: { somenteAutenticado: true },
    },
  ]
  