
export default [
    {
      name: 'Listar Tabela',
      path: '/areas/:id/tabela',
      component: () => import('@/views/pages/tabelas/TabelaListar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Adicionar Tabela',
      path: '/areas/:id/tabela/adicionar',
      component: () => import('@/views/pages/tabelas/TabelaAdicionar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Editar Tabela',
      path: '/areas/:idArea/tabela/:id/editar',
      component: () => import('@/views/pages/tabelas/TabelaEditar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Mostrar Tabela',
      path: '/areas/:idArea/tabela/:id/mostrar',
      component: () => import('@/views/pages/tabelas/TabelaMostrar'),
      meta: { somenteAutenticado: true },
    },
  ]
  