<template>
  <v-autocomplete
    :value="value"
    dense
    outlined
    label="Forma de cobrança"
    :items="formaCobranca"
    item-text="descricao"
    item-value="id"
    :readonly="readonly"
    :loading="carregando"
    :disabled="carregando"
    :error-messages="errorMessages"
    autocomplete="false"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: Array,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formaCobranca: [
        { id: 1, descricao: "Pré - Pago" },
        { id: 2, descricao: "Pos - Pago" },
      ],
      carregando: false,
    };
  },

  methods: {},
};
</script>
