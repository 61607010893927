<template>
  <v-card>
    <v-card-title class="text-h5 bg-corDoPix"> Pagar com Pix </v-card-title>

    <v-card-text class="d-flex justify-center align-center pa-4 flex-column">
      <h1
        class="my-5 planoAtual"
        v-text="this.$store.state.plano.planoAtual.complemento"
      />

      <qr-code
        v-if="link"
        :key="cont"
        :text="link"
        :size="tamanho"
        :color="isDarkTheme"
        bg-color="transparent"
      />
      <v-progress-circular indeterminate :size="128" :width="12" v-else />

      <h1 class="mt-3 text-h3">
        R$ {{ this.$store.state.plano.planoAtual.valor_mensal }}
      </h1>

      <h3
        class="my-2 text-uppercase"
        style="cursor: pointer"
        @click="copiarCodigo"
        v-text="'Copiar o Código'"
      />

      <p class="planoPendente" ref="planoStatus">
        Status: {{ status_descricao }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import gatewaypayments from "@/api/gatewaypayments";
import empresas from "@/api/empresas";

export default {
  data() {
    return {
      tamanho: 250,
      cont: 0,
      status_descricao: "Carregando...",
      link: false,
      token: null,
      intervalId: null,
      mensalidade: 0,
    };
  },

  computed: {
    isDarkTheme() {
      this.forceRender();
      return this.$vuetify.theme.dark ? "grey" : "black";
    },
  },

  beforeMount() {
    this.verMensalidade();
  },

  methods: {
    forceRender() {
      this.cont++;
    },

    copiarCodigo() {
      navigator.clipboard.writeText(this.link);
    },

    async verMensalidade() {
      try {
        const resposta = await empresas.mensalidadesEmpresa(
          this.$store.state.usuario.usuario.empresa_id
        );

        this.mensalidade = resposta.data.data.pop().id;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.fazerOPix();
      }
    },

    async fazerOPix() {
      try {
        const resposta = await gatewaypayments.gerarPIX({
          empresa_id: this.$store.state.usuario.usuario.empresa_id,
          plano_id: this.$store.state.usuario.usuario.empresa.plano_id,
          valor: this.$store.state.plano.planoAtual.valor_mensal,
          mensalidade_id: this.mensalidade,
        });

        this.status_descricao = resposta.data.data.status_descricao;
        this.link = resposta.data.data.qrcode_pix;
        this.token = resposta.data.data.identificador_pix;

        this.forceRender();
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.ValidaPagamento();
      }
    },

    async ValidaPagamento() {
      let repeatCount = 0;
      this.intervalId = setInterval(async () => {
        try {
          const resposta = await gatewaypayments.ReconsultarPix(this.token);
          let rep = resposta.data.data.status_descricao;

          if (rep === "Aprovado") {
            clearInterval(this.intervalId);
            this.status_descricao = "Pago";
            this.$snackbar.mostrar({
              cor: "success",
              mensagem: "Pago!!!",
            });

            let planoStatus = this.$refs.planoStatus;
            planoStatus.classList.remove("planoPendente");
            planoStatus.classList.add("planoPago");

            setInterval(() => {
              location.reload();
            }, 3000);
          }
        } catch (e) {
          this.$snackbar.mostrar({
            cor: "error",
            mensagem: this.$erroApi._(e),
          });
          clearInterval(this.intervalId);
        }

        repeatCount++;
        if (repeatCount >= 10) {
          clearInterval(this.intervalId);
        }
      }, 30000);
    },
  },
};
</script>

<style scoped>
.bg-corDoPix {
  background-color: #00bdae;
  color: white;
  text-transform: uppercase;
  justify-content: center;
}
.planoAtual {
  color: #ffab40;
  font-weight: bolder;
}
.planoPendente {
  font-weight: bolder;
  color: #42a5f5;
}
.planoPago {
  font-weight: bolder;
  color: lightgreen;
}
</style>