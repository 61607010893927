import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import layout from "@/store/modules/layout";
import usuario from "@/store/modules/usuario";
import modal from "@/store/modules/modal";
import plano from "@/store/modules/plano";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    usuario,
    modal,
    plano,
  },
  actions: {
    logout({ commit }) {
      commit("usuario/SET_USUARIO", null);
      commit("usuario/SET_PERMISSOES", null);
      commit("layout/SET_ALERTAS", []);
      commit("layout/SET_SNACKBAR", { mostrar: false });
    },
  },
  plugins: [
    createPersistedState({
      key: "vuex",
      reducer(val) {
        if (val.usuario.access_token === null) {
          // limpa tudo ao fazer o logout
          return {};
        }

        return {
          usuario: val.usuario, // salva somente o modulo do usuario
        };
      },
    }),
  ],
});
