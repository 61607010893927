
export default [
    {
      name: 'Listar Box',
      path: '/areas/:id/box',
      component: () => import('@/views/pages/box/BoxListar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Adicionar Box',
      path: '/areas/:id/box/adicionar',
      component: () => import('@/views/pages/box/BoxAdicionar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Editar Box',
      path: '/areas/:idArea/box/:id/editar',
      component: () => import('@/views/pages/box/BoxEditar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Mostrar Box',
      path: '/areas/:idArea/box/:id/mostrar',
      component: () => import('@/views/pages/box/BoxMostrar'),
      meta: { somenteAutenticado: true },
    },
  ]
  