export default [
    // {
    //     name: "Listar Veículos",
    //     path: "/veiculos",
    //     component: () => import("@/views/pages/veiculos/VeiculosListar"),
    //     meta: { somenteAutenticado: true },
    // },
    // {
    //     name: "Adicionar Veículos",
    //     path: "/veiculos/adicionar",
    //     component: () => import("@/views/pages/veiculos/VeiculosAdicionar"),
    //     meta: { somenteAutenticado: true },
    // },
    // {
    //     name: "Editar Veiuclo",
    //     path: "/veiculos/:id/editar",
    //     component: () => import("@/views/pages/veiculos/VeiculosEditar"),
    //     meta: { somenteAutenticado: true },
    // },
];
