export default [
    {
        name: 'Esqueci Senha',
        path: '/esquecisenha',
        component: () => import('@/views/pages/senha/SenhaEsqueciSenha'),
        meta: { redirecionarSeAutenticado: false },
      },
      {
        name: 'Redefinir Senha',
        path: '/redefinirsenha',
        component: () => import('@/views/pages/senha/SenhaRedefinirSenha'),
        meta: { redirecionarSeAutenticado: false },
      },
  ]
  