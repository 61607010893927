export default [
    {
      name: 'Listar Grupos',
      path: '/grupos',
      component: () => import('@/views/pages/grupos/GruposListar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Adicionar Grupos',
      path: '/grupos/adicionar',
      component: () => import('@/views/pages/grupos/GruposAdicionar'),
      meta: { somenteAutenticado: true },
    },
    {
      name: 'Editar Grupos',
      path: '/grupos/:id/editar',
      component: () => import('@/views/pages/grupos/GruposEditar'),
      meta: { somenteAutenticado: true },
    },
  ]
  