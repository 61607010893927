export default {
  namespaced: true,
  state: {
    planoAtual: [],
    NovoPlano: [],
    tickets_usados: null,
  },
  mutations: {
    SET_TICKETS_USADO(state, payload) {
      state.tickets_usados = payload;
    },
    SET_PLANO(state, payload) {
      state.planoAtual = payload;
    },
    SET_NOVOPLANO(state, payload) {
      state.NovoPlano = payload;
    },
  },
};
