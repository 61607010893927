<template>
  <div id="chart">
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
      :key="semanalMensal"
    />
    <v-btn @click="apexLineChart()" elevation="3" class="ml-5 px-10">{{
      label
    }}</v-btn>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    chartdata: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    label: "Mensal",
    semanalMensal: 1,
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      colors: ["#2460A7FF", "#85B3D1FF", "#B3C7D6FF", "#D9B48FFF"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Tipos de pagamentos",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Semana",
        },
      },
      yaxis: {
        title: {
          text: "Valores",
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        floating: true,
        offsetY: -15,
        offsetX: -5,
      },
    },
  }),
  mounted() {
    this.apexLineChart();
  },

  methods: {
    async apexLineChart() {
      try {
        const chartOptions = { ...this.chartOptions };
        chartOptions.xaxis.categories = [];
        const series = [];
        let dados = [];
        let quantidadeDias = 0;

        if (this.semanalMensal === 1) {
          dados = this.chartdata.tipos_pagamentos_semanal;
          quantidadeDias = 6;
          this.chartOptions.xaxis.title.text = "Semanal";
          this.label = "Mensal";
        } else {
          dados = this.chartdata.tipos_pagamentos_mensal;
          quantidadeDias = 29;
          this.chartOptions.xaxis.title.text = "Mensal";
          this.label = "Semanal";
        }

        const tiposDePagamentos = [
          ...new Set(dados.map((item) => item.descricao)),
        ];

        const hoje = moment();
        const dias = [hoje.format("YYYY-MM-DD")]
          .concat(
            Array(quantidadeDias)
              .fill()
              .map(() => hoje.subtract(1, "d").format("YYYY-MM-DD"))
          )
          .reverse();

        for (const dia of dias) {
          const dadosDia = dados.filter((d) => d.data_pagamento === dia);

          for (const tipoDePagamento of tiposDePagamentos) {
            const dadosDiaTipoPagamento = dadosDia.filter(
              (d) => d.descricao === tipoDePagamento
            );

            const testeIndexSerie = series
              .map((e) => e.name)
              .indexOf(tipoDePagamento);
            if (testeIndexSerie < 0) {
              series.push({
                name: tipoDePagamento,
                data: [],
              });
            }

            const indexSerie = series
              .map((e) => e.name)
              .indexOf(tipoDePagamento);
            const valor =
              dadosDiaTipoPagamento.length <= 0
                ? 0
                : dadosDiaTipoPagamento[0].valor;

            series[indexSerie].data.push(valor);
          }

          chartOptions.xaxis.categories.push(moment(dia).format("DD/MM/YYYY"));
        }

        this.chartOptions = chartOptions;
        this.series = series;
        this.semanalMensal = this.semanalMensal === 1 ? 2 : 1;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
