<template>
  <v-autocomplete
    :value="value"
    dense
    outlined
    label="Adquirente"
    :items="adquirentes"
    item-text="descricao"
    item-value="id"
    :readonly="readonly"
    :loading="carregando"
    :disabled="carregando"
    :error-messages="errorMessages"
    autocomplete="false"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: Array,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      adquirentes: [
        { id: 1, descricao: "STONE" },
        { id: 2, descricao: "SKYTEF" },
        { id: 4, descricao: "SAFRA" },
      ],
      carregando: false,
    };
  },

  methods: {},
};
</script>
