<template>
    <v-btn
        icon
        plain
        :class="{
            'button-white': $vuetify.theme.dark,
        }"
        v-if="temPermissao"
        :to="href"
        class="text-decoration-none"
    >
        <v-icon small class="mr-2"> mdi-clipboard-text-outline </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "TableButtonContrato",

    props: {
        href: {
            type: String,
            default: "",
        },
        temPermissao: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--icon {
    color: black;
}
</style>
