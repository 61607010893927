import axios from "@/plugins/axios";

export default {
  async listar() {
    return await axios.get("permissoes");
  },
  async listarPermissoesUsuarios() {
    return await axios.get("permissoes/usuarios");
  },
  async listarAgrupadores() {
    return await axios.get("permissoes/agrupadores");
  },

  //permissoes/agrupadores - get
  //retorna id e descricao
};
