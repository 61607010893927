import axios from "@/plugins/axios";

export default {
  async totalAreas() {
    return await axios.get(`dashboard/areas`);
  },
  
  async totalPagamentoMensal() {
    let totalPagamentoMensal = [
      {
        name: "Exemplo 1",
        data: [
          1,
          7,
          43,
          20,
          21,
          25,
          27,
          41,
          23,
          10,
          5,
          2,
          23,
          20,
          1,
          2,
          8,
          9,
          4,
          6,
          2,
          3,
          5,
          2,
          6,
          7,
          2,
          1,
          5,
          7,
          9,
        ],
      },
      {
        name: "Exemplo 2",
        data: [
          1,
          7,
          43,
          9,
          4,
          6,
          2,
          3,
          5,
          2,
          6,
          7,
          2,
          1,
          5,
          7,
          23,
          20,
          1,
          2,
          8,
          9,
          4,
          6,
          2,
          3,
          5,
          2,
          6,
          7,
        ],
      },
      {
        name: "Exemplo 3",
        data: [
          1,
          7,
          43,
          23,
          20,
          1,
          2,
          8,
          9,
          4,
          6,
          2,
          3,
          5,
          2,
          6,
          7,
          2,
          1,
          5,
          7,
          9,
          20,
          21,
          25,
          27,
          41,
          23,
          10,
          5,
          2,
        ],
      },
    ];

    return totalPagamentoMensal;
  },
  async totalPagamentoSemanal() {
    let totalPagamentoSemanal =[
      {
        name: "Exemplo 1",
        data: [28, 29, 33, 36, 32, 40],
      },
      {
        name: "Exemplo 2",
        data: [12, 11, 14, 18, 17, 26],
      },
      {
        name: "Exemplo 3",
        data: [5, 0, 10, 15, 23, 24],
      },
    ];

    return totalPagamentoSemanal;
  },
};
