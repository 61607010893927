export default (cpfCnpj) => {
  if (!cpfCnpj) return ''

  const numeros = cpfCnpj.replace(/[^\d]/g, '')

  if (numeros.length === 11) {
    return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (numeros.length === 14) {
    return numeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else {
    return numeros
  }
}
