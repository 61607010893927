export default (string, tamanho = 8) => {
    if (!string) return ''

    string = string.toString()

    if (string.length <= tamanho) {
      return string
    }

    return string.substr(0, tamanho) + '...'
}
