import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const estado_id = dados.estado_id
    const por_pagina = dados.por_pagina
    return await axios.get(`estados/`+estado_id+'/cidades?por_pagina='+por_pagina)
  },

}
