import Vue from "vue";

import BtnAdicionar from "@/components/button/ButtonAdicionar";
import BtnSalvar from "@/components/button/ButtonSalvar";
import BtnVoltar from "@/components/button/ButtonVoltar";
import BtnBuscar from "@/components/button/ButtonBuscar";
import BtnVisualizar from "@/components/button/ButtonVisualizar";

import TableBtnEditar from "@/components/table/TableButtonEditar";
import TableBtnMostrar from "@/components/table/TableButtonMostrar";
import TableBtnContrato from "@/components/table/TableButtonContrato";
import TableBtnVeiculo from "@/components/table/TableButtonVeiculo";

import TabRotasBox from "@/components/tab/TabRotasBox";
import TabRotasTabelas from "@/components/tab/TabRotasTabelas";
import TabRotasTiposPagamentos from "@/components/tab/TabRotasTiposPagamentos";
import TabRotasConvenios from "@/components/tab/TabRotasConvenio";

import Carregando from "@/components/loading/Carregando";

import ModalBase from "@/components/modalDialog/ModalBase.vue";
import TicketMontar from "@/components/modalDialog/TicketMontar.vue";
import PagarPix from "@/components/modalDialog/PagarPix.vue";
import TrocarPlano from "@/components/modalDialog/TrocarPlano.vue";

import PieChart from "@/components/charts/ChartPie";
import ApexChart from "@/components/charts/ChartApexLine";
import GraficosAreas from "@/components/charts/ChartAreas";
import GraficosAreasLista from "@/components/charts/ChartAreasLista";

import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import AutocompleteEstados from "@/components/input/InputAutocompleteEstados";
import AutocompleteCidades from "@/components/input/InputAutocompleteCidades";
import AutoCompleteAreas from "@/components/input/InputAutocompleteAreas";
import AutocompleteTipoVeiculos from "@/components/input/InputAutocompleteTipoVeiculos";
import AutocompleteDias from "@/components/input/InputAutocompleteDias";
import AutoCompleteTipoPagamentos from "@/components/input/InputAutoCompleteTipoPagamentos";
import AutoCompleteFormaCobranca from "@/components/input/InputAutoCompleteFormaCobranca";
import AutoCompleteAdquirente from "@/components/input/InputAutoCompleteAdquirente";
import AutoCompleteConvenio from "@/components/input/InputAutoCompleteConvenio";
import AutoCompletePlano from "@/components/input/InputAutoCompletePlano";

import PasswordGenerator from "@/components/passwordGenerator/passwordgenerator";

import CoreSnackbar from "@/components/core/CoreSnackbar";

Vue.component("btn-adicionar", BtnAdicionar);
Vue.component("btn-salvar", BtnSalvar);
Vue.component("btn-voltar", BtnVoltar);
Vue.component("btn-buscar", BtnBuscar);
Vue.component("btn-visualizar", BtnVisualizar);

Vue.component("table-btn-editar", TableBtnEditar);
Vue.component("table-btn-mostrar", TableBtnMostrar);
Vue.component("table-btn-contrato", TableBtnContrato);
Vue.component("table-btn-veiculo", TableBtnVeiculo);

Vue.component("tab-rotas-box", TabRotasBox);
Vue.component("tab-rotas-tabelas", TabRotasTabelas);
Vue.component("tab-rotas-tipos-pagamentos", TabRotasTiposPagamentos);
Vue.component("tab-rotas-convenios", TabRotasConvenios);
Vue.component("ticket-montar", TicketMontar);
Vue.component("pagar-pix", PagarPix);
Vue.component("modal-base", ModalBase);
Vue.component("trocar-plano", TrocarPlano);
Vue.component("carregando", Carregando);

Vue.component("graficos-areas", GraficosAreas);
Vue.component("graficos-areas-lista", GraficosAreasLista);
Vue.component("apex-line-charts", ApexChart);
Vue.component("grafico-pie", PieChart);

Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

Vue.component("autocomplete-estados", AutocompleteEstados);
Vue.component("autocomplete-cidades", AutocompleteCidades);
Vue.component("autocomplete-areas", AutoCompleteAreas);
Vue.component("autocomplete-tipo-veiculos", AutocompleteTipoVeiculos);
Vue.component("autocomplete-dias", AutocompleteDias);
Vue.component("autocomplete-tipo-pagamento", AutoCompleteTipoPagamentos);
Vue.component("autocomplete-forma-cobranca", AutoCompleteFormaCobranca);
Vue.component("autocomplete-adquirente", AutoCompleteAdquirente);
Vue.component("autocomplete-convenio", AutoCompleteConvenio);
Vue.component("autocomplete-plano", AutoCompletePlano);

Vue.component("password-generator", PasswordGenerator);

Vue.component("core-snackbar", CoreSnackbar);
