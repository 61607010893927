<template>
  <v-card class="pa-2 overflow-hidden">
    <h4 class="text-center text-h4 text-uppercase">
      Confirmar Trocar de
      <span class="planoAtual">
        {{ $store.state.plano.planoAtual.descricao }}
      </span>
      <p class="text-h4 text-uppercase">
        Para
        <span class="planoNovo">
          {{ $store.state.plano.NovoPlano.descricao }}
        </span>
      </p>
    </h4>

    <v-row class="my-2 d-flex flex-column justify-center align-center">
      <p class="text-capitalize">
        <v-icon color="#ffab40"> mdi-plus-box </v-icon>
        Ticket Disponíveis:
        <span class="font-weight-black">
          {{ $store.state.plano.NovoPlano.tickets }}
        </span>
      </p>
      <p class="text-capitalize">
        <v-icon color="#ffab40"> mdi-plus-box </v-icon>
        Áreas Disponíveis:
        <span class="font-weight-black">
          {{ $store.state.plano.NovoPlano.numero_areas }}
        </span>
      </p>
      <p class="text-capitalize">
        <v-icon color="#ffab40"> mdi-plus-box </v-icon>
        Novo valor:
        <span class="font-weight-black">
          R$
          {{ $store.state.plano.NovoPlano.valor_mensal }}
        </span>
      </p>
      
      <p class="text-capitalize font-weight-bold" style="color: #f44336">
        fatura atual será substituída por uma nova fatura
      </p>

      <v-row>
        <v-col v-if="carregando" class="d-flex justify-center align-center">
          <v-btn color="green textWhite" raised x-large @click="atualizar()">
            <v-icon left> mdi-check-bold </v-icon>Confirmar
          </v-btn>
        </v-col>
        <v-col v-if="carregando" class="d-flex justify-center align-center">
          <v-btn
            color="red textWhite"
            raised
            x-large
            @click="$store.commit(`modal/SET_MODAL`, false)"
          >
            <v-icon left> mdi-keyboard-return </v-icon>Voltar
          </v-btn>
        </v-col>
        <v-col v-if="!carregando">
          <v-progress-circular
            indeterminate
            :size="56"
            :width="9"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import empresas from "@/api/empresas";

export default {
  data() {
    return {
      carregando: true,
    };
  },
  mounted() {
    console.log(this.$store.state);
  },

  methods: {
    async atualizar() {
      try {
        this.carregando = false;
        let dados = { plano_id: this.$store.state.plano.NovoPlano.id };
        const resposta = await empresas.upgradePlano(
          this.$store.state.usuario.usuario.empresa.id,
          dados
        );
        console.log(resposta.data);

        this.$store.state.usuario.usuario.empresa.plano_id =
          this.$store.state.plano.NovoPlano.id;

        this.carregando = false;
        this.$store.commit(`modal/SET_MODAL`, false);

        this.$snackbar.mostrar({
          cor: "success",
          mensagem: "Novo plano cadastrado com sucesso!",
        });

        setInterval(() => {
          location.reload();
        }, 1500);

        location.reload();
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`modal/SET_MODAL`, false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.planoAtual {
  color: #ffab40;
  font-weight: bolder;
}
.planoNovo {
  font-weight: bolder;

  color: #42a5f5;
}
.textWhite {
  color: white;
}
</style>