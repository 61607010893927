<template>
  <div class="d-flex align-center flex-column">
      <v-col class="d-flex justify-center">
        Gerar senha
      </v-col>
      <v-card class="my-1">
        <input
          :type="type"
          class="form-control"
          :placeholder="placeholder"
          v-model="password"
        />
      </v-card>
      <v-col class="d-flex justify-center">
        <v-btn type="button" class="btn btn-primary" @click="generate()">
          <v-icon>
            mdi-lock-open
          </v-icon>
        </v-btn>
      </v-col>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    size: {
      type: String,
      default: "32",
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#",
    },
    placeholder: {
      type: String,
      default: "Password",
    },
    auto: [String, Boolean],
    value: null,
  },
  data: function() {
    return {};
  },

  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted: function() {
    if (this.auto == "true" || this.auto == 1) {
      this.generate();
    }
  },
  methods: {
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
    },
  },
};
</script>
<style scoped>
.form-control {
  background-color: #e8f0f1;
  text-align: center;
}
</style>
