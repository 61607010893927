export default [
  {
    name: 'Listar Usuários Empresas',
    path: 'usuariosEmpresas/',
    component: () => import('@/views/pages/usuariosEmpresas/UsuariosEmpresasListar'),
    meta: { somenteAutenticado: true },
  },
  /*
  {
    name: 'Adicionar Usuários Empresas',
    path: 'empresas/:id/usuariosEmpresas/adicionar',
    component: () => import('@/views/pages/usuariosEmpresas/UsuariosEmpresasAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Usuários Empresas',
    path: 'empresas/:idEmpresa/usuariosEmpresas/:id/editar',
    component: () => import('@/views/pages/usuariosEmpresas/UsuariosEmpresasEditar'),
    meta: { somenteAutenticado: true },
  },
  */
 
]
