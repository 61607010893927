import Vue from "vue";
import Router from "vue-router";
//import { Router} from "vue-router";
import store from "@/store/index";
import Login from "../views/pages/Login";
import senha from "./modules/senha";
import empresas from "./modules/empresas";
import usuarios from "./modules/usuarios";
import usuariosEmpresas from "./modules/usuariosEmpresas";
import areas from "./modules/areas";
import box from "./modules/box";
import tabelas from "./modules/tabelas";
import feriados from "./modules/feriados";
import permissoes from "./modules/permissoes";
import grupos from "./modules/grupos";
import tiposPagamentos from "./modules/tiposPagamentos";
import relatorios from "./modules/relatorios";
import clientes from "./modules/clientes";
import contratos from "./modules/contratos";
import veiculos from "./modules/veiculos";
import areaConfiguracaoUser from "./modules/areaConfiguracaoUser";
import convenios from "./modules/convenios";
import areaConfiguracaoAdmin from "./modules/areaConfiguracaoAdmin";
import planos from "./modules/planos";
import planoUser from "./modules/planoUser";

Vue.use(Router);

const routes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: { redirecionarSeAutenticado: true },
  },
  ...senha,
  {
    path: "/",
    component: () => import("@/views/Index"),
    children: [
      {
        name: "Dashboard",
        path: "",
        component: () => import("@/views/pages/dashboard/Dashboard"),
        meta: { somenteAutenticado: true },
      },
      ...empresas,
      ...usuarios,
      ...usuariosEmpresas,
      ...areas,
      ...tiposPagamentos,
      ...box,
      ...grupos,
      ...tabelas,
      ...permissoes,
      ...feriados,
      ...relatorios,
      ...clientes,
      ...contratos,
      ...veiculos,
      ...areaConfiguracaoUser,
      ...areaConfiguracaoAdmin,
      ...convenios,
      ...planos,
      ...planoUser,
    ],
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  var token = null;
  if (store.state.usuario.usuario != null) {
    token = store.state.usuario.usuario.access_token;
  }

  if (to.matched.some((record) => record.meta.somenteAutenticado)) {
    if (!token) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else if (
    to.matched.some((record) => record.meta.redirecionarSeAutenticado)
  ) {
    if (token) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
