export default [
  {
    name: "Todos Planos",
    path: "/planos",
    component: () => import("@/views/pages/planos/PlanosListar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Adicionar Plano",
    path: "/planos/adicionar",
    component: () => import("@/views/pages/planos/PlanosAdicionar"),
    meta: { somenteAutenticado: true },
  },
  {
    name: "Editar Plano",
    path: "/planos/:id/editar",
    component: () => import("@/views/pages/planos/PlanosEditar"),
    meta: { somenteAutenticado: true },
  },
];
