<template>
  <v-autocomplete
    :value="value"
    dense
    outlined
    label="Dia da semana"
    :items="diasDaSemana"
    item-text="descricao"
    item-value="id"
    :readonly="readonly"
    :loading="carregando"
    :disabled="carregando"
    :error-messages="errorMessages"
    autocomplete="false"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
  import dias from '@/api/dias'

  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      errorMessages: {
        type: Array,
        default: () => {},
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        diasDaSemana: [],
        carregando: false,
      }
    },

    mounted () {
      this.DiasdaSemana()
    },

    methods: {

      async DiasdaSemana () {
        try {
          this.carregando = true

          const resposta = await dias.listar()

          this.diasDaSemana = resposta.data.data
          
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },

  }
</script>
