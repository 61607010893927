import axios from "@/plugins/axios";

export default {
  async buscar(id) {
    return await axios.get(`areas/${id}`);
  },

  async inserir(dados) {
    return await axios.post("areas", dados);
  },

  async atualizar(id, dados) {
    return await axios.put(`areas/${id}`, dados);
  },

  async deletar(id) {
    return await axios.delete(`areas/${id}`);
  },

  async listarAreasBox(id, dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`areas/${id}/boxes?${parametros}`);
  },

  async listarAreasTabela(id, dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`areas/${id}/tabelas?${parametros}`);
  },

  async listarAreasTiposPagamentos(id) {
    return await axios.get(`areas/${id}/tipos-pagamentos`);
  },
  
  async listarConvenios(id, dados) {
    const parametros = new URLSearchParams(dados).toString();
    return await axios.get(`areas/${id}/convenios?${parametros}`);
  },
};
