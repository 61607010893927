<template>
  <v-overlay
    :carregandoBuscar="carregandoBuscar"
    :carregandoSalvar="carregandoSalvar"
    :opacity="opacidade"
   >
    <v-progress-circular
      indeterminate
      color="white"
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    opacidade: {
      type: Number,
      default: 0.46,
    },
  },

  name: "Carregando",
  data() {
    return {
      carregandoBuscar: false,
      carregandoSalvar: false,
    };
  },
};
</script>
<style scoped>
.v-overlay {
  align-items: flex-start;
  padding-top: 20%;
  position: absolute;
}
</style>
