import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`planos?${parametros}`)
  },

  async buscar (id) {
    return await axios.get(`planos/${id}`)
  },

  async inserir (dados) {
    return await axios.post('planos', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`planos/${id}`, dados)
  },

  async deletar (id) {
    return await axios.delete(`planos/${id}`)
  },
 
}