export default {
  namespaced: true,
  state: {
    usuario: null,
    permissoes: [],
  },
  mutations: {
    SET_USUARIO(state, payload) {
      state.usuario = payload;
    },
    SET_PERMISSOES(state, payload) {
      state.permissoes = payload;
    },
  },
  getters: {
    temPermissao: (state) => (slug) => {
      if (!state.usuario) {
        return false;
      }

      let superAdmin = false;

      state.usuario.grupos.forEach((item) => {
        if (item.id == 1) {
          superAdmin = true;
        }
      });

      if (!state.permissoes) {
        return false;
      }
      const permissao = state.permissoes.filter((p) => p.slug === slug);

      return permissao.length > 0 || superAdmin;
    },
  },
};
