import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import snackbar from "./plugins/snackbar";
import mensagens from "@/plugins/mensagens";
import erroApi from "@/plugins/erro-api";
import { VueMaskDirective } from "v-mask";
import "@/plugins/vee-validate";
import "@/plugins/componentes";
import "@/plugins/filters";
import "@/utils/google-maps";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";
import "./plugins/vuetify-mask.js";
import DatetimePicker from "vuetify-datetime-picker";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueQRCodeComponent from "vue-qrcode-component";

Chart.plugins.unregister(ChartDataLabels);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(snackbar);
Vue.use(mensagens);
Vue.use(erroApi);
Vue.use(ChartDataLabels);
Vue.use(DatetimePicker);
Vue.component("qr-code", VueQRCodeComponent);

Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
