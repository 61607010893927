import store from '@/store/index'

export default {
  install: (Vue) => {
    Vue.prototype.$snackbar = {

      mostrar: function ({ mensagem = null, cor = null, timeout = 5000 }) {
        store.commit('layout/SET_SNACKBAR', { mensagem, cor, timeout })
      },

    }
  },
}
