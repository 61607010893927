export default [
  //   {
  //     name: "Listar Contratos do Cliente",
  //     path: "/contratos/cliente/:id",
  //     component: () => import("@/views/pages/contratos/ContratoListar"),
  //     meta: { somenteAutenticado: true },
  //   },
  //   {
  //     name: "Adicionar Contrato",
  //     path: "/contratos/adicionar",
  //     component: () => import("@/views/pages/contratos/ContratoAdicionar"),
  //     meta: { somenteAutenticado: true },
  //   },
  // {
  //     name: "Editar Contrato",
  //     path: "/contratos/:id/editar",
  //     component: () => import("@/views/pages/contratos/ContratoEditar"),
  //     meta: { somenteAutenticado: true },
  // },
];
