export default {
  namespaced: true,
  state: {
    drawer: null,
    snackbar: {
      mostrar: false,
      cor: null,
      mensagem: null,
      timeout: 5000,
    },
    alertas: [],
    loading: false,
 
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_ALERTAS(state, payload) {
      state.alertas = payload;
    },
    SET_SNACKBAR(
      state,
      { mostrar = true, cor = null, mensagem = null, timeout = 5000 }
    ) {
      state.snackbar.mostrar = mostrar;
      state.snackbar.cor = cor;
      state.snackbar.mensagem = mensagem;
      state.snackbar.timeout = timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
};
