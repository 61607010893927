<template>
  <v-btn color="grey" depressed :to="to" class="espaco-esquerda">
    <v-icon class="mr-2"> mdi-chevron-left </v-icon>
    Voltar
  </v-btn>
</template>

<script>
export default {
  name: "ButtonVoltar",

  props: {
    to: {
      type: String,
      default: "/",
    },
  },
};
</script>
<style scoped>
</style>