<template>
  <v-autocomplete
    :value="value"
    dense
    outlined
    label="Tipo de Veículo"
    :items="tipoVeiculos"
    item-text="descricao"
    item-value="id"
    :readonly="readonly"
    :loading="carregando"
    :disabled="carregando"
    :error-messages="errorMessages"
    autocomplete="false"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
import tipoVeiculos from "@/api/tipoveiculos";

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: Array,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tipoVeiculos: [],
      carregando: false,
    };
  },

  mounted() {
    this.buscarVeiculos();
  },

  methods: {
    async buscarVeiculos() {
      try {
        this.carregando = true;

        const resposta = await tipoVeiculos.listar();

        this.tipoVeiculos = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
