import axios from "axios";
import store from "@/store";
import router from "@/router/index";

const api = axios.create({
    baseURL: process.env.VUE_APP_URL_SERVIDOR + '/api/',
    timeout: 15000,
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(function(config) {
    if (store.state.usuario.usuario == null) {
        return config;
    }
    if (typeof store.state.usuario.usuario.access_token !== "undefined") {
        api.defaults.headers.common['Bearer'] = store.state.usuario.usuario.access_token;
        config.headers.Authorization = `Bearer ${store.state.usuario.usuario.access_token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async function(error) {
        if (
            error.response &&
            error.response.status === 401 &&
            store.state.usuario.usuario &&
            store.state.usuario.usuario.access_token
        ) {
            store.dispatch("logout");
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

export default api;
