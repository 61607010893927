export default [
  {
    name: 'Listar Usuários',
    path: '/usuarios',
    component: () => import('@/views/pages/usuarios/UsuariosListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Usuário',
    path: '/usuarios/adicionar',
    component: () => import('@/views/pages/usuarios/UsuariosAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Usuário',
    path: '/usuarios/:id/editar',
    component: () => import('@/views/pages/usuarios/UsuariosEditar'),
    meta: { somenteAutenticado: true },
  },
  {
  name: 'Mostrar Usuário',
  path: '/usuarios/:id/mostrar',
  component: () => import('@/views/pages/usuarios/UsuariosMostrar'),
  meta: { somenteAutenticado: true },
  },
]
